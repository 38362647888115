import React from 'react';
import { Link } from 'react-router-dom';
const Sitemap = () => {

    return (
        <main>
            <section className="page-header">
                <div className="page-header__bg"></div>
                <div className="container">
                    <div className="page-header__inner">
                        <h2>Sitemap</h2>
                        <div className="thm-breadcrumb__box">
                            <ul className="thm-breadcrumb">
                                <li><Link to="/">Home</Link></li>
                                <li><span>::</span></li>
                                <li>Sitemap</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </main>

    );
};
export default Sitemap;