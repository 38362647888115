import React from 'react';
import { Link } from 'react-router-dom';
const Ecommerce = () => {

    return (
        <main>
        {/* <!--Page Header Start--> */}
    <section class="page-header">
        <div class="page-header__bg"></div>
        <div class="container">
            <div class="page-header__inner">
                <h2>E-Commerce Websites</h2>
                <div class="thm-breadcrumb__box">
                    <ul class="thm-breadcrumb">
                    <li><Link to="/">Home</Link></li>
                        <li><span>::</span></li>
                        <li><Link to="/services">services</Link></li>
                        <li><span>::</span></li>
                        <li>E-Commerce</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    {/* <!--Page Header End--> */}

        {/* <!--Services Details Start--> */}
    <section className="services-details">
        <div className="container">
            <h3 className="services-details__title-1">E-Commerce Services</h3>
            <p className="services-details__text-1">Discover the best products and unbeatable deals. We are committed to providing high-quality products, exceptional customer service, and a seamless shopping experience.</p>
            {/* <div className="services-details__img-1">
                <img src="images/services/services-details-img-1.jpg" alt=""/>
            </div> */}
            <div className="services-details__middle">
                <div className="row">
                    
                    <div className="col-xl-8 col-lg-8">
                        <div className="services-details__middle-content">
                            <h3 className="services-details__middle-title">WordPress Plugin Development</h3>
                            <p className="services-details__middle-text">Our developers build custom plugins to extend the functionality of your WordPress site. Whether you need a simple widget or a complex plugin, we ensure it integrates seamlessly with your website.</p>
                            <p className="services-details__middle-text">We provide comprehensive e-commerce solutions using WooCommerce, the leading WordPress e-commerce platform. From setting up your store to customizing its features, we help you create a seamless online shopping experience.</p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4">
                        <div className="services-details__middle-img">
                            <img src="images/services/services-details-middle-img-1.jpg" alt=""/>
                        </div>
                    </div>
                    
                </div>
            </div>
          
            
            
        </div>
    </section>
    {/* <!--Services Details End--> */}

    {/* <!--Why Choose One Start--> */}
		<section class="why-choose-one">
			<div class="why-choose-one__bg" style={{ backgroundImage:' url(images/background/why-choose-one-bg.jpg);'}}>
			</div>
			<div class="container">
				<div class="row">
					<div class="col-xl-7">
						<div class="why-choose-one__left">
							<div class="section-title text-left">
								<div class="section-title__tagline-box">
									<span class="section-title__tagline">. : WHY CHOOSE US : .</span>
								</div>
								<h2 class="section-title__title">Make Your Service. </h2>
							</div>
							<p class="why-choose-one__text">We build custom e-commerce websites that are designed to meet your unique business requirements. Our solutions ensure a seamless shopping experience for your customers and a robust backend for easy management.</p>

							<div class="why-choose-one__shape-1 float-bob-y">
								<img src="images/shapes/why-choose-one-shape-1.png" alt=""/>
							</div>

							<div class="why-choose-one__shape-2 img-bounce">
								<img src="images/shapes/why-choose-one-shape-2.png" alt=""/>
							</div>

							<ul class="why-choose-one__points clearfix">
								<li class="cs-hover_tab-3 active clearfix">
									<div class="icon">
										<span class="icon-creative-solution"></span>
									</div>
									<div class="content">
										<h3>1. E-Commerce Platform Solutions</h3>
										<p>We work with the leading e-commerce platforms to deliver versatile and feature-rich online stores. Our team is experienced in customizing and optimizing these platforms to suit your specific needs.</p>
									</div>
									<div class="why-choose-one__img-box"
										data-src="images/resource/why-choose-one-img.jpg">
									</div>
								</li>
								<li class="cs-hover_tab-3 clearfix">
									<div class="icon">
										<span class="icon-figma-design"></span>
									</div>
									<div class="content">
										<h3>2. Payment Gateway Integration</h3>
										<p>We integrate secure and reliable payment gateways to provide your customers with a seamless and secure checkout experience. Our solutions support multiple payment methods and currencies.</p>
									</div>
									<div class="why-choose-one__img-box"
										data-src="images/resource/why-choose-one-img-2.jpg"></div>
								</li>
								<li class="cs-hover_tab-3 clearfix">
									<div class="icon">
										<span class="icon-wireframing"></span>
									</div>
									<div class="content">
										<h3>3. Product Management Systems</h3>
										<p>Our product management systems make it easy for you to add, update, and manage your inventory. We ensure that your product catalog is well-organized and easily navigable for your customers.</p>
									</div>
									<div class="why-choose-one__img-box"
										data-src="images/resource/why-choose-one-img.jpg"></div>
								</li>
								<div class="about-one__btn-box">
                                            <Link to="/services" className="about-one__btn thm-btn text-white">View All Our Services</Link>
                                        </div>
							</ul>
						</div>
					</div>

				</div>
			</div>
		</section>
		{/* <!--Why Choose One End--> */}

    </main>

    );
};
export default Ecommerce;