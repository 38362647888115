import React from 'react';
import { Link } from 'react-router-dom';
const Clients = () => {

    return (
        <main>
            <section class="team-one">
                <div class="team-one__bg"></div>
                <div className='container'>
                    <div class="team-one__top">
                        <div class="team-one__top-left">
                            <div class="section-title text-center">
                                <div class="section-title__tagline-box">
                                    <span class="section-title__tagline">. : Our Awesome Clients : .</span>
                                </div>
                                <h2 class="section-title__title">Here are some of our clients</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div className='col-lg-3 col-md-4 col-sm-4 text-center'>
                            <div className='clientbox'>
                                <Link to="https://sdesignstudios.in/" target="_blank">
                                    <img src="images/sdesign.png" alt="" />
                                </Link>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-4 text-center'>
                            <div className='clientbox'>
                                <img src="images/sivakasi_crackers.png" alt="" />
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-4 text-center'>
                            <div className='clientbox'>
                                <img src="images/infogates.png" alt="" />
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-4 text-center'>
                            <div className='clientbox'>
                                <img src="images/Dcreate.png" alt="" />
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-4 text-center'>
                            <div className='clientbox'>
                                <Link to="https://www.thennaitreatz.in/" target="_blank">
                                    <img src="images/thennaitreatz.png" alt="" />
                                </Link>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-4 text-center'>
                            <div className='clientbox'>
                                <img src="images/ultrafly.png" alt="" />
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-4 text-center'>
                            <div className='clientbox'>
                                <img src="images/gravity.png" alt="" />
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-4 text-center'>
                            <div className='clientbox'>
                                <img src="images/srisivasakthikalaikoodam.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>


    );
};
export default Clients;