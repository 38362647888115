import React from 'react';
import { Link } from 'react-router-dom';
const Services = () => {

    return (
        <main>
            <section className="page-header">
                <div className="page-header__bg"></div>
                <div className="container">
                    <div className="page-header__inner">
                        <h2>Services</h2>
                        <div className="thm-breadcrumb__box">
                            <ul className="thm-breadcrumb">
                                <li><Link to="/">Home</Link></li>
                                <li><span>::</span></li>
                                <li>Services</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            
            {/* <!-- Blog Two Start --> */}
            <section class="blog-two">
                <div class="blog-two__shape-bg"></div>
                <div class="container">
                    <div class="section-title text-center">
                        <h2 class="section-title__title">We are Expertise in</h2>
                    </div>
                    <div class="row">
                        {/* <!-- Blog Two Single Start --> */}
                        <div class="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms">
                            <div class="blog-two__single">
                                <div class="blog-two__img">
                                    <img src="images/blog/ui-ux.jpg" alt="" />
                                </div>
                                <div class="blog-two__content">
                                    <h3 class="blog-two__title"><Link to="/uiux">UI / UX Designing</Link></h3>
                                    <Link to="/uiux" class="blog-two__btn">Read More <span
                                            class="icon-bottom-right"></span></Link>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Blog Two Single End --> */}
                        {/* <!-- Blog Two Single Start --> */}
                        <div class="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">
                            <div class="blog-two__single">
                                <div class="blog-two__img">
                                    <img src="images/blog/branding.jpg" alt="" />
                                </div>
                                <div class="blog-two__content">
                                    <h3 class="blog-two__title"><Link to="/branding">Branding</Link></h3>
                                    <Link to="/branding" class="blog-two__btn">Read More <span
                                            class="icon-bottom-right"></span></Link>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Blog Two Single End --> */}
                        {/* <!-- Blog Two Single Start --> */}
                        <div class="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms">
                            <div class="blog-two__single">
                                <div class="blog-two__img">
                                    <img src="images/blog/webdev.jpg" alt="" />
                                </div>
                                <div class="blog-two__content">
                                    <h3 class="blog-two__title"><Link to="/webdev">Web Development</Link></h3>
                                    <Link to="/webdev" class="blog-two__btn">Read More <span
                                            class="icon-bottom-right"></span></Link>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Blog Two Single End --> */}
                        {/* <!-- Blog Two Single Start --> */}
                        <div class="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms">
                            <div class="blog-two__single">
                                <div class="blog-two__img">
                                    <img src="images/blog/wordpress.jpg" alt="" />
                                </div>
                                <div class="blog-two__content">
                                    <h3 class="blog-two__title"><Link to="/wordpressdev">Wordpress Developement</Link></h3>
                                    <Link to="/wordpressdev" class="blog-two__btn">Read More <span
                                            class="icon-bottom-right"></span></Link>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Blog Two Single End --> */}
                        {/* <!-- Blog Two Single Start --> */}
                        <div class="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">
                            <div class="blog-two__single">
                                <div class="blog-two__img">
                                    <img src="images/blog/ecom.jpg" alt="" />
                                </div>
                                <div class="blog-two__content">
                                    <h3 class="blog-two__title"><Link to="/ecommerce">Ecommerce Websites</Link></h3>
                                    <Link to="/ecommerce" class="blog-two__btn">Read More <span
                                            class="icon-bottom-right"></span></Link>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Blog Two Single End --> */}
                        {/* <!-- Blog Two Single Start --> */}
                        <div class="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms">
                            <div class="blog-two__single">
                                <div class="blog-two__img">
                                    <img src="images/blog/seo.jpg" alt="" />
                                </div>
                                <div class="blog-two__content">
                                    <h3 class="blog-two__title"><Link to="/seo">SEO Optimization</Link></h3>
                                    <Link to="/seo" class="blog-two__btn">Read More <span
                                            class="icon-bottom-right"></span></Link>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Blog Two Single End --> */}
                    </div>
                </div>
            </section>
            {/* <!-- Blog Two End --> */}


        </main>

    );
};
export default Services;