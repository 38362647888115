import React from 'react';
import { Link } from 'react-router-dom';
// import './Footer.css';


const Footer = () => {
  return (
		<footer className="main-footer-two">
			<div className="main-footer-two__bg">
			</div>
			<div className="main-footer-two__top">
				<div className="container">
					<div className="main-footer-two__inner">
						<div className="row">
							<div className="col-xl-6 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms">
								<div className="footer-widget-two__column footer-widget-two__about">
									<div className="footer-widget-two__logo">
									<Link to="/"><img src="images/resource/footer-logo.png" alt="" /></Link>
									</div>
									<p className="footer-widget-two__about-text">We aim to build solutions that help
										businesses grow revenue significantly. In the age of information
										technology,opportunities and challenges.</p>
									<div className="site-footer-two__social">
										<a href="#"><i className="icon-facebook-app-symbol"></i></a>
										<a href="#"><i className="icon-instagram"></i></a>
										<a href="#"><i className="icon-linkedin"></i></a>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms">
								<div className="footer-widget-two__column footer-widget-two__link">
									<div className="footer-widget-two__title-box">
										<h3 className="footer-widget-two__title">Us Links</h3>
									</div>
									<div className="footer-widget-two__link-box">
										<ul className="footer-widget-two__link-list list-unstyled">
											<li><Link to="/about"> <span className="icon-play-button"></span> About</Link>
											</li>
											<li><Link to="/team"> <span className="icon-play-button"></span> Meet the Team</Link></li>
											<li><Link to="/"><span className="icon-play-button"></span> Our Projects</Link></li>
											<li><Link to="/"> <span className="icon-play-button"></span> Our FAQs</Link></li>
											<li><Link to="/contact"><span className="icon-play-button"></span>Contact</Link></li>
										</ul>
									</div>
								</div>
							</div>
							<div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="400ms">
								<div className="footer-widget-two__column footer-widget-two__contact">
									<div className="footer-widget-two__title-box">
										<h3 className="footer-widget-two__title">Contact Us</h3>
									</div>
									<ul className="footer-widget-two__contact-list">
										<li>
											<div className="icon">
												<span className="icon-location"></span>
											</div>
											<div className="text">
												<p>Eacham Pottal Pudur, <br /> Tenkasi, Tamilnadu - 627755</p>
											</div>
										</li>
										<li>
											<div className="icon">
												<span className="icon-phone-1"></span>
											</div>
											<div className="text">
												<p><a >Phone: +91 956 693 2342</a></p>
											</div>
										</li>
										<li>
											<div className="icon">
												<span className="icon-mail-1"></span>
											</div>
											<div className="text">
												<p><a >info@techtag.in</a></p>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="main-footer-three main-footer-two__bottom">
				<div className="container">
					<div class="main-footer-two__bottom-inner">
						<p class="main-footer-two__bottom-text">© {new Date().getFullYear()} TechTag All Rights Reserved</p>
						<div class="site-footer-two__social mainnfooter gap-4">
							<Link to="term-of-use">Terms of use</Link>
							<Link to="privacy-policy">Privacy Policy</Link>
							<Link to="site-map">Sitemap</Link>
						</div>
					</div>
				</div>
			</div>
		</footer>
  );
};

export default Footer;
