import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import About from './components/About';
import Services from './components/Services';
import UiuxDesign from './components/UiuxDesign';
import Branding from './components/Branding';
import WebDevelopement from './components/WebDevelopment';
import WordpressDev from './components/WordpressDev';
import Ecommerce from './components/Ecommerce';
import SeoOptimization from './components/SeoOptimization';
import OurWorks from './components/OurWorks';
import Clients from './components/Clients';
import Contact from './components/Contact';
import Terms from './components/Terms';
import PrivacyPoilcy from './components/PrivacyPolicy';
import Sitemap from './components/Sitemap';
import ScrollToTopButton from './components/ScrollToTopButton';
import { BodyClassProvider } from './components/BodyClassContext';
// google-site-verification=utR0bv8R5_ab-yOQ9ZelQDJ5RoV2xv9-6Iay9MRHR5E
function App() {
  return (
    <BodyClassProvider>
    <Router>
      <div className="App">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="services" element={<Services />} />
            <Route path="uiux" element={<UiuxDesign />} />
            <Route path="branding" element={<Branding />} />
            <Route path="webdev" element={<WebDevelopement />} />
            <Route path="wordpressdev" element={<WordpressDev />} />
            <Route path="ecommerce" element={<Ecommerce />} />
            <Route path="seo" element={<SeoOptimization />} />
            <Route path="works" element={<OurWorks />} />
            <Route path="clients" element={<Clients />} />
            <Route path="term-of-use" element={<Terms />} />
            <Route path="privacy-policy" element={<PrivacyPoilcy />} />
            <Route path="site-map" element={<Sitemap />} />
          </Routes>
        </main>

        <Footer />

        <ScrollToTopButton />

      </div>
    </Router>
    </BodyClassProvider>
  );
}

export default App;
