import React from 'react';

const HomePage = () => {
    return (
        <main>
		{/* // <!-- Banner Two Start --> */}
		<section className="banner-two">
			<div className="banner-two__bg"></div>
			<div className="container">
				<div className="banner-two__inner">
					<div className="banner-two__top wow fadeInLeft" data-wow-delay="100ms" data-wow-duration="2500ms">
						<h2 className="banner-two__top-title">Innovative</h2>
						<div className="banner-two__top-text-box">
							<p className="banner-two__top-text">We bring your creative vision to life. As a leading creative development agency, we specialize in delivering innovative solutions that transform ideas into captivating digital experiences.

</p>
						</div>
					</div>
					<div className="banner-two__bottom">
						<div className="banner-two__arrow float-bob-y">
							<a href="#"><span className="icon-long-bottom-arrow"></span></a>
						</div>
						<h2 className="banner-two__bottom-title wow fadeInRight" data-wow-delay="100ms"
							data-wow-duration="2500ms">Development <span>Solutions</span> </h2>
					</div>
				</div>
			</div>
		</section>
		{/* // <!-- Banner Two End --> */}

		{/* <!-- Banner Img Start --> */}
		<section className="banner-img">
			<div className="container">
				<div className="banner-img__img">
					<img src="images/resource/banner-img-img.jpg" alt="" />
				</div>
			</div>
		</section>
		{/* <!-- Banner Img End --> */}

		{/* <!-- About Three Start --> */}
		<section className="about-three">
			<div className="about-three__bg"></div>
			<div className="container">
				<div className="row">
					<div className="col-xl-6">
						<div className="about-three__left">
							<div className="about-three__img-box wow slideInLeft" data-wow-delay="100ms"
								data-wow-duration="2500ms">
								<div className="about-three__img">
									<img src="images/resource/about.jpg" alt="" />
								</div>
								<div className="about-three__img-2">
									<img src="images/resource/about1.jpg" alt="" />
								</div>
								<div className="about-three__shape-1"></div>
								<div className="about-three__video-link">
									<a href="/" className="lightbox-video">
										<div className="about-three__video-icon">
											<span className="icon-instagram"></span>
											<i className="ripple"></i>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
					<div className="col-xl-6">
						<div className="about-three__right">
							<div className="section-title text-left">
								<div className="section-title__tagline-box">
									<span className="section-title__tagline">. : ABOUT COMPANY : .</span>
								</div>
								<h2 className="section-title__title">Empowering Businesses with Smart Digital Solutions</h2>
							</div>
							<p className="about-three__text">We provide comprehensive digital marketing services, including SEO, social media management, and content creation. Our strategies are data-driven and designed to maximize your online visibility and engagement.</p>
							<p className="about-three__text-2">Creating Smarter Digital Experiences Every Day</p>
							<p className="about-three__text-3">Our talented designers craft visually compelling graphics that communicate your brand message effectively. From logos to marketing materials, we ensure your brand stands out with unique and impactful designs.</p>
							<div className="about-three__counter-box">
								<ul className="about-three__counter-list">
									<li>
										<div className="about-three__count count-box">
											<h3 className="count-text" data-stop="12" data-speed="1500">25</h3>
											<span>+</span>
										</div>
										<p>Happy Users <br /> Around World</p>
									</li>
									<li>
										<div className="about-three__count count-box">
											<h3 className="count-text" data-stop="30" data-speed="1500">15</h3>
											<span>+</span>
										</div>
										<p>Projects <br /> Already Done</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		{/* <!-- About Three End --> */}

		{/* <!-- Services One Start --> */}
		<section className="services-one">
			<div className="services-one__bg"></div>
			<div className="container">
				<div className="section-title text-center">
					<div className="section-title__tagline-box">
						<span className="section-title__tagline">. : Trusted web design service : .</span>
					</div>
					<h2 className="section-title__title">Transforming Ideas into 
						<br />Smart Digital Realities </h2>
				</div>
				<div className="row">
					{/* <!--Services One Single Start--> */}
					<div className="col-xl-4 col-lg-4 col-md-4 wow fadeInLeft" data-wow-delay="100ms">
						<div className="services-one__single">
							<div className="services-one__img">
								<img src="images/services/services-1-1.jpg" alt="" />
							</div>
							<div className="services-one__arrow">
								<a href="/services"><span className="icon-bottom-right"></span></a>
							</div>
							<div className="services-one__count"></div>
							<h3 className="services-one__title"><a href="/services">UI / UX Design</a></h3>
							<p className="services-one__text">We transform ideas into tangible concepts with detailed wireframes and interactive prototypes. This stage allows us to visualize the user journey, test functionalities, and refine the design before development begins.</p>
						</div>
					</div>
					{/* <!--Services One Single End--> */}
					{/* <!--Services One Single Start--> */}
					<div className="col-xl-4 col-lg-4 col-md-4 wow fadeInRight" data-wow-delay="200ms">
						<div className="services-one__single">
							<div className="services-one__img">
								<img src="images/services/services-1-2.jpg" alt="" />
							</div>
							<div className="services-one__arrow">
								<a href="/services"><span className="icon-bottom-right"></span></a>
							</div>
							<div className="services-one__count"></div>
							<h3 className="services-one__title"><a href="/services">Branding</a></h3>
							<p className="services-one__text">We help you build a strong and cohesive brand identity. Our team works closely with you to develop a brand strategy that resonates with your target audience and sets you apart from the competition.</p>
						</div>
					</div>
					{/* <!--Services One Single End--> */}
					{/* <!--Services One Single Start--> */}
					<div className="col-xl-4 col-lg-4 col-md-4 wow fadeInLeft" data-wow-delay="300ms">
						<div className="services-one__single">
							<div className="services-one__img">
								<img src="images/services/services-1-3.jpg" alt="" />
							</div>
							<div className="services-one__arrow">
								<a href="/services"><span className="icon-bottom-right"></span></a>
							</div>
							<div className="services-one__count"></div>
							<h3 className="services-one__title"><a href="/services">Web Development</a></h3>
							<p className="services-one__text">Our expert developers create dynamic, responsive websites that are both visually stunning and highly functional. We ensure your online presence is engaging and user-friendly, tailored to meet your business goals.</p>
						</div>
					</div>
					{/* <!--Services One Single End--> */}
					{/* <!--Services One Single Start--> */}
					<div className="col-xl-4 col-lg-4 col-md-4 wow fadeInRight" data-wow-delay="400ms">
						<div className="services-one__single">
							<div className="services-one__img">
								<img src="images/services/services-1-4.jpg" alt="" />
							</div>
							<div className="services-one__arrow">
								<a href="/services"><span className="icon-bottom-right"></span></a>
							</div>
							<div className="services-one__count"></div>
							<h3 className="services-one__title"><a href="/services">Wordpress Developement</a></h3>
							<p className="services-one__text">We specialize in creating powerful, customizable, and scalable WordPress websites that help businesses thrive online. Our team of experienced developers is dedicated to delivering top-notch solutions tailored to your unique needs.</p>
						</div>
					</div>
					{/* <!--Services One Single End--> */}
					{/* <!--Services One Single Start--> */}
					<div className="col-xl-4 col-lg-4 col-md-4 wow fadeInRight" data-wow-delay="400ms">
						<div className="services-one__single">
							<div className="services-one__img">
								<img src="images/services/services-1-4.jpg" alt="" />
							</div>
							<div className="services-one__arrow">
								<a href="/services"><span className="icon-bottom-right"></span></a>
							</div>
							<div className="services-one__count"></div>
							<h3 className="services-one__title"><a href="/services">Ecommerce Websites</a></h3>
							<p className="services-one__text">Our team designs custom e-commerce websites that reflect your brand identity and provide an engaging user experience. We focus on creating intuitive navigation, appealing layouts, and responsive designs that look great on any device.

</p>
						</div>
					</div>
					{/* <!--Services One Single End--> */}
					{/* <!--Services One Single Start--> */}
					<div className="col-xl-4 col-lg-4 col-md-4 wow fadeInRight" data-wow-delay="400ms">
						<div className="services-one__single">
							<div className="services-one__img">
								<img src="images/services/services-1-4.jpg" alt="" />
							</div>
							<div className="services-one__arrow">
								<a href="/services"><span className="icon-bottom-right"></span></a>
							</div>
							<div className="services-one__count"></div>
							<h3 className="services-one__title"><a href="/services">SEO Optimization</a></h3>
							<p className="services-one__text">Our SEO and digital marketing services help drive traffic to your Websites. We optimize your website for search engines, implement effective marketing strategies, and track performance to ensure maximum visibility and conversions.</p>
						</div>
					</div>
					{/* <!--Services One Single End--> */}
				</div>
			</div>
		</section>
		{/* <!-- Services One End --> */}




		{/* <!-- Testimonial Two Start --> */}
		<section className="testimonial-two">
			<div className="testimonial-two__shape-bg float-bob-y"></div>
			<div className="container">
				<div className="testimonial-two__inner">
					<div className="testimonial-two__sec-title-box">
						<div className="section-title text-left">
							<div className="section-title__tagline-box">
								<span className="section-title__tagline">. : know what our clients says : .</span>
							</div>
							<h2 className="section-title__title">Digital Excellence That <br />
							Delights!</h2>
						</div>
					</div>
					<div className="testimonial-two__nav">
						<div className="swiper-button-prev" id="testimonial-two__swiper-button-next">
							<i className="icon-arrow-left"></i>
						</div>
						<div className="swiper-button-next" id="testimonial-two__swiper-button-prev">
							<i className="icon-arrow-right"></i>
						</div>
					</div>
					<div className="thm-swiper__slider swiper-container testimonial-two__carousel" data-swiper-options='{"spaceBetween": 30, "slidesPerView": 1, 
							"loop": true,
							"pagination": {
								"el": "#testimonial-one-pagination",
								"type": "bullets",
								"clickable": true
								},
								"navigation": {
								"nextEl": "#testimonial-two__swiper-button-next",
								"prevEl": "#testimonial-two__swiper-button-prev"
								}, 
								"autoplay": { "delay": 5000 }, 
								"breakpoints": {
								"0": {
									"spaceBetween": 30,
									"slidesPerView": 1
								},
								"375": {
									"spaceBetween": 30,
									"slidesPerView": 1
								},
								"575": {
									"spaceBetween": 30,
									"slidesPerView": 1
								},
								"767": {
									"spaceBetween": 30,
									"slidesPerView": 1
								},
								"991": {
									"spaceBetween": 30,
									"slidesPerView": 1
								},
								"1199": {
									"spaceBetween": 30,
									"slidesPerView": 1
								}
							}}'>
						<div className="swiper-wrapper">
							{/* <!--Testimonial One Single Start --> */}
							<div className="swiper-slide">
								<div className="testimonial-two__main-content-box">
									<div className="row">
										<div className="col-xl-4 col-lg-5">
											<div className="testimonial-two__main-content-left">
												<div className="testimonial-two__client-info">
													<div className="testimonial-two__shape-1">
														<img src="images/shapes/testimonial-two-shape-1.png" alt="" />
													</div>
													<div className="testimonial-two__client-img">
														<img src="images/testimonial/testimonial-two-client-1-1.jpg"
															alt="" />
													</div>
													<div className="testimonial-two__client-content-box">
														<div className="testimonial-two__client-circle"></div>
														<div className="testimonial-two__client-content">
															<h5 className="testimonial-two__client-name">Leonard Heizer</h5>
															<p className="testimonial-two__client-sub-title">CEO</p>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-8 col-lg-7">
											<div className="testimonial-two__main-content-right">
												<div className="testimonial-two__rating">
													<span className="icon-star-1"></span>
													<span className="icon-star-1"></span>
													<span className="icon-star-1"></span>
													<span className="icon-star-1"></span>
													<span className="icon-star-1"></span>
												</div>
												<p className="testimonial-two__text">I have been hiring people in this space
													for a number of years and I have never seen this level of
													professionalism. It really feels like you are working with a team
													that can get the job done.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* <!--Testimonial One Single End --> */}
							{/* <!--Testimonial One Single Start --> */}
							<div className="swiper-slide">
								<div className="testimonial-two__main-content-box">
									<div className="row">
										<div className="col-xl-4 col-lg-5">
											<div className="testimonial-two__main-content-left">
												<div className="testimonial-two__client-info">
													<div className="testimonial-two__shape-1">
														<img src="images/shapes/testimonial-two-shape-1.png" alt="" />
													</div>
													<div className="testimonial-two__client-img">
														<img src="images/testimonial/testimonial-two-client-1-1.jpg"
															alt="" />
													</div>
													<div className="testimonial-two__client-content-box">
														<div className="testimonial-two__client-circle"></div>
														<div className="testimonial-two__client-content">
															<h5 className="testimonial-two__client-name">Look Ronchi</h5>
															<p className="testimonial-two__client-sub-title">CEO</p>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-8 col-lg-7">
											<div className="testimonial-two__main-content-right">
												<div className="testimonial-two__rating">
													<span className="icon-star-1"></span>
													<span className="icon-star-1"></span>
													<span className="icon-star-1"></span>
													<span className="icon-star-1"></span>
													<span className="icon-star-1"></span>
												</div>
												<p className="testimonial-two__text">I have been hiring people in this space
													for a number of years and I have never seen this level of
													professionalism. It really feels like you are working with a team
													that can get the job done.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* <!--Testimonial One Single End -->
							<!--Testimonial One Single Start --> */}
							<div className="swiper-slide">
								<div className="testimonial-two__main-content-box">
									<div className="row">
										<div className="col-xl-4 col-lg-5">
											<div className="testimonial-two__main-content-left">
												<div className="testimonial-two__client-info">
													<div className="testimonial-two__shape-1">
														<img src="images/shapes/testimonial-two-shape-1.png" alt="" />
													</div>
													<div className="testimonial-two__client-img">
														<img src="images/testimonial/testimonial-two-client-1-1.jpg"
															alt="" />
													</div>
													<div className="testimonial-two__client-content-box">
														<div className="testimonial-two__client-circle"></div>
														<div className="testimonial-two__client-content">
															<h5 className="testimonial-two__client-name">Adam Smith</h5>
															<p className="testimonial-two__client-sub-title">CEO</p>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-8 col-lg-7">
											<div className="testimonial-two__main-content-right">
												<div className="testimonial-two__rating">
													<span className="icon-star-1"></span>
													<span className="icon-star-1"></span>
													<span className="icon-star-1"></span>
													<span className="icon-star-1"></span>
													<span className="icon-star-1"></span>
												</div>
												<p className="testimonial-two__text">I have been hiring people in this space
													for a number of years and I have never seen this level of
													professionalism. It really feels like you are working with a team
													that can get the job done.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							{/* <!--Testimonial One Single End --> */}
						</div>
					</div>
				</div>
			</div>
		</section>
		{/* <!-- Testimonial Two End --> */}



		{/* <!-- Pricing One Start --> */}
		<section class="pricing-one">
			<div class="pricing-one__shape-1">
			</div>
			<div class="container">
				<div class="section-title text-center">
					<div class="section-title__tagline-box">
						<span class="section-title__tagline">. : PRICING TABLE : .</span>
					</div>
					<h2 class="section-title__title">All Professional Lat’s Look Our <br />
						Recent Pricing Table</h2>
				</div>
				<div class="row">
					{/* <!-- Pricing One Single Start --> */}
					<div class="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms">
						<div class="pricing-one__single">
							<div class="pricing-one__single-inner">
								<div class="pricing-one__icon">
									<span class="icon-website-design"></span>
								</div>
								<div class="pricing-one__price-box">
									<p>Per Year</p>
									<h2>Rs 5999</h2>
								</div>
								<div class="pricing-one__title-box">
									<h4 class="pricing-one__title">STANDARD PLAN</h4>
								</div>
								<div class="pricing-one__service-list-box">
									<h5 class="pricing-one__service-list-title">All Services Include:</h5>
									<ul class="pricing-one__service-list">
									<li>3 Page Web Design(Responsive)</li>
										<li>1 Year of Web Hosting</li>
										<li>Domain Name</li>
										<li>Local Search Engine Optimization</li>
										<li>WordPress (or another platform) Content Management System</li>
										<li>Social Media Links</li>
									</ul>
								</div>
								<div class="pricing-one__arrow">
									<a href="/"><span class="icon-arrow-upper-right"></span></a>
								</div>
							</div>
						</div>
					</div>
					{/* <!-- Pricing One Single End -->
					<!-- Pricing One Single Start --> */}
					<div class="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">
						<div class="pricing-one__single">
							<div class="pricing-one__single-inner">
								<div class="pricing-one__icon">
									<span class="icon-app-development"></span>
								</div>
								<div class="pricing-one__price-box">
									<p>Per Year</p>
									<h2>Rs 7999</h2>
								</div>
								<div class="pricing-one__title-box">
									<h4 class="pricing-one__title">PREMIUM PLAN</h4>
								</div>
								<div class="pricing-one__service-list-box">
									<h5 class="pricing-one__service-list-title">All Services Include:</h5>
									<ul class="pricing-one__service-list">
										<li>6 Page Web Design(Responsive)</li>
										<li>1 Year of Web Hosting</li>
										<li>Domain Name</li>
										<li>Local Search Engine Optimization</li>
										<li>WordPress (or another platform) Content Management System</li>
										<li>Technical Support</li>
									</ul>
								</div>
								<div class="pricing-one__arrow">
									<a href="/"><span class="icon-arrow-upper-right"></span></a>
								</div>
							</div>
						</div>
					</div>
					{/* <!-- Pricing One Single End --> */}
					{/* <!-- Pricing One Single Start --> */}
					<div class="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms">
						<div class="pricing-one__single">
							<div class="pricing-one__single-inner">
								<div class="pricing-one__icon">
									<span class="icon-digital-marketing"></span>
								</div>
								<div class="pricing-one__price-box">
									<p>Per Year</p>
									<h2>Rs 9999</h2>
								</div>
								<div class="pricing-one__title-box">
									<h4 class="pricing-one__title">E-COMMERCE</h4>
								</div>
								<div class="pricing-one__service-list-box">
									<h5 class="pricing-one__service-list-title">All Services Include:</h5>
									<ul class="pricing-one__service-list">
									<li>6 Page Web Design(Responsive)</li>
										<li>1 Year of Web Hosting</li>
										<li>Domain Name</li>
										<li>Local Search Engine Optimization</li>
										<li>WordPress (or another platform) Content Management System</li>
										<li>Technical Support</li>
									</ul>
								</div>
								<div class="pricing-one__arrow">
									<a href="/"><span class="icon-arrow-upper-right"></span></a>
								</div>
							</div>
						</div>
					</div>
					{/* <!-- Pricing One Single End --> */}
				</div>
			</div>
		</section>
		{/* <!-- Pricing One End --> */}



		{/* <!-- Newsletter One Start --> */}
		<section class="newsletter-one">
			<div class="container">
				<div class="row">
					<div class="col-xl-6 col-lg-6">
						<div class="newsletter-one__left">
							<div class="section-title text-left">
								<div class="section-title__tagline-box">
									<span class="section-title__tagline">. : GET IN TOUCH : .</span>
								</div>
								<h2 class="section-title__title">Subscribe Now.
								</h2>
							</div>
						</div>
					</div>
					<div class="col-xl-6 col-lg-6">
						<div class="newsletter-one__right">
							<div class="newsletter-one__form-box">
								<h5 class="newsletter-one__title">Your Mail Address:</h5>
								<form class="newsletter-one__newsletter-form" novalidate="novalidate">
									<div class="newsletter-one__newsletter-form-input-box">
										<input type="email" placeholder="info@example.com" name="EMAIL" />
										<button type="submit" class="newsletter-one__newsletter-btn">Now <br />
											Subscribe <span class="icon-arrow-upper-right"></span> </button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		{/* <!-- Newsletter One End --> */}


        </main>

    );
};
export default HomePage;