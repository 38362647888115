import React from 'react';
import { Link } from 'react-router-dom';
const Branding = () => {

    return (
        <main>
                     	{/* <!--Page Header Start--> */}
		<section class="page-header">
			<div class="page-header__bg"></div>
			<div class="container">
				<div class="page-header__inner">
					<h2>Branding</h2>
					<div class="thm-breadcrumb__box">
						<ul class="thm-breadcrumb">
                        <li><Link to="/">Home</Link></li>
							<li><span>::</span></li>
							<li><Link to="/services">services</Link></li>
                            <li><span>::</span></li>
							<li>Branding</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
		{/* <!--Page Header End--> */}

            {/* <!--Services Details Start--> */}
		<section className="services-details p-0 mt-5">
			<div className="container">
				<h3 className="services-details__title-1">Branding Services at TechTag</h3>
				<p className="services-details__text-1">We understand that a strong brand is the cornerstone of a successful business. Our comprehensive branding services are designed to help you establish a unique identity, connect with your audience, and stand out in the marketplace. We help you define a clear and compelling brand strategy that aligns with your business goals. Our approach includes market research, competitive analysis, and identifying your unique value proposition to create a roadmap for your brand’s success.</p>
		
			</div>
            
		</section>
		{/* <!--Services Details End--> */}

        <section class="project-details p-0">
        <div class="container">
        <div class="project-details__content">
						<div class="row">
							
							<div class="col-xl-12 col-lg-12">
								<div class="project-details__content-right">
									<h3 class="project-details__content-title">We develop powerful brand messaging that communicates your brand’s story, mission, and values effectively.</h3>
									<p class="project-details__content-text">Whether you’re looking to refresh your brand or completely overhaul your identity, we offer rebranding services that breathe new life into your business. We work with you to redefine your brand and align it with your evolving goals and audience. We create cohesive social media branding strategies that amplify your brand’s presence online. Our services include content creation, visual design, and engagement tactics to build a strong and active community around your brand.</p>
									                                   
                                    <div class="project-details__content-points-box">
										<ul class="project-details__content-points">
											<li>
												<div class="project-details__content-shape"></div>
												<p>Brand Strategy Development</p>
											</li>
											<li>
												<div class="project-details__content-shape"></div>
												<p>Brand Identity Design</p>
											</li>
											<li>
												<div class="project-details__content-shape"></div>
												<p>Brand Messaging & Positioning</p>
											</li>
											<li>
												<div class="project-details__content-shape"></div>
												<p>Logo, typography, and color schemes</p>
											</li>
										</ul>
										<ul class="project-details__content-points project-details__content-points-2">
											<li>
												<div class="project-details__content-shape"></div>
												<p>Rebranding</p>
											</li>
											<li>
												<div class="project-details__content-shape"></div>
												<p>Brand Experience Design</p>
											</li>
											<li>
												<div class="project-details__content-shape"></div>
												<p>Comprehensive brand performance analysis</p>
											</li>
											<li>
												<div class="project-details__content-shape"></div>
												<p>Social Media Branding</p>
											</li>
											
										</ul>
									
									</div>

									<div class="d-flex justify-content-end about-one__btn-box">
                                            <Link to="/services" className="about-one__btn thm-btn">View All Our Services</Link>
                                        </div>
                                    
								</div>
							</div>
						</div>
					</div>
                    </div>
                    </section>
        </main>

    );
};
export default Branding;