import React from 'react';
import { Link } from 'react-router-dom';
const SeoOptimization = () => {

    return (
        <main>
        	{/* <!--Page Header Start--> */}
		<section class="page-header">
			<div class="page-header__bg"></div>
			<div class="container">
				<div class="page-header__inner">
					<h2>SEO Optimization</h2>
					<div class="thm-breadcrumb__box">
						<ul class="thm-breadcrumb">
                        <li><Link to="/">Home</Link></li>
							<li><span>::</span></li>
							<li><Link to="/services">services</Link></li>
                            <li><span>::</span></li>
							<li>SEO Optimization</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
		{/* <!--Page Header End--> */}

            {/* <!--Services Details Start--> */}
		<section className="services-details">
			<div className="container">
				<h3 className="services-details__title-1">SEO Optimization Services</h3>
				<p className="services-details__text-1">At TECHTAG, we specialize in delivering comprehensive SEO solutions designed to boost your online visibility, drive organic traffic, and increase conversions. Our expert team utilizes proven strategies and the latest techniques to ensure your website ranks high on search engines.</p>
				{/* <div className="services-details__img-1">
					<img src="images/services/services-details-img-1.jpg" alt=""/>
				</div> */}
				<div className="services-details__middle">
					<div className="row">
						
						<div className="col-xl-8 col-lg-8">
							<div className="services-details__middle-content">
								<h3 className="services-details__middle-title">Keyword Research and Analysis</h3>
								<p className="services-details__middle-text">Understanding the right keywords is the foundation of a successful SEO strategy. We conduct thorough keyword research to identify the terms and phrases your target audience uses, ensuring your content aligns with their search intent.</p>
                                <p className="services-details__middle-text">Optimize your website’s content and structure to improve its relevance and visibility to search engines. Our on-page SEO services ensure your website is both user-friendly and search engine-friendly.</p>
                            </div>
						</div>
                        <div className="col-xl-4 col-lg-4">
							<div className="services-details__middle-img">
								<img src="images/services/services-details-middle-img-1.jpg" alt=""/>
							</div>
						</div>
						
					</div>
				</div>
				<div className="services-details__what-we-do">
					<div className="row">
                    <div className="col-xl-4 col-lg-5">
							<div className="services-details__what-we-do-img mb-3">
								<img src="images/services/services-details-what-we-do-img.jpg" alt=""/>
							</div>
                            <div className="services-details__included-services">
								<h4 className="services-details__included-services-title">Included Services</h4>
								<ul className="services-details__included-services-list">
									<li>
										<div className="services-details__included-services-shape"></div>
										<p className="services-details__included-services-text">Monthly SEO Task</p>
									</li>
									<li>
										<div className="services-details__included-services-shape"></div>
										<p className="services-details__included-services-text">24/7 Alltime Supporting</p>
									</li>
									<li>
										<div className="services-details__included-services-shape"></div>
										<p className="services-details__included-services-text">Turbo Boosting</p>
									</li>
									<li>
										<div className="services-details__included-services-shape"></div>
										<p className="services-details__included-services-text">Social Media Marketing</p>
									</li>
                                    <li>
										<div className="services-details__included-services-shape"></div>
										<p className="services-details__included-services-text">Domain & Hosting</p>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-xl-8 col-lg-7">
							<div className="services-details__what-we-do-left">
								<h3 className="services-details__what-we-do-title">Technical SEO</h3>
								<p className="services-details__what-we-do-text-1">Ensure your website’s technical foundation is solid to improve its crawling and indexing by search engines. Our technical SEO services address site speed, mobile-friendliness, and other critical factors.

Site speed optimization,
Mobile optimization,
XML sitemap creation,
Robots.txt file optimization,
SSL certificate installation.</p>
								<h3 className="services-details__what-we-do-title">Content Marketing and SEO Copywriting</h3>
                                <p className="services-details__what-we-do-text-1">Quality content is key to engaging your audience and ranking well on search engines. We create valuable, relevant, and optimized content that attracts and retains your target audience.

SEO-friendly blog posts and articles,
Website copywriting,
Content strategy development,
Guest posting and link-building content.</p>
                                <h3 className="services-details__what-we-do-title">SEO & Performance Optimization</h3>
                                <p className="services-details__what-we-do-text-1">We optimize your WordPress site for search engines and performance, helping you achieve higher rankings and faster load times. Our SEO services include keyword research, on-page optimization, and content strategy.</p>
                                <h3 className="services-details__what-we-do-title">Innovative Solutions & Proven Success</h3>
                                <p className="services-details__what-we-do-text-1">We stay ahead of design trends and leverage the latest tools and technologies to create innovative solutions that give you a competitive edge. Our portfolio speaks for itself. We have successfully designed digital products that enhance user satisfaction, drive engagement, and achieve business goals.</p>
                            </div>
						</div>
						<div class="d-flex justify-content-end about-one__btn-box">
                                            <Link to="/services" className="about-one__btn thm-btn">View All Our Services</Link>
                                        </div>
					</div>
				</div>
				
				
			</div>
		</section>
		{/* <!--Services Details End--> */}
        </main>

    );
};
export default SeoOptimization;