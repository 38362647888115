import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
const Contact = () => {
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (data) => {
    console.log(data)
    emailjs
      .send("service_g4dsx3l", "template_m4mqdjc", data,"TgnV7fgyqfOcwf8yc")
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          reset(); // Reset the form after successful submission
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  };

  return (
    <main>
      {/* <!--Page Header Start--> */}
      <section className="page-header">
        <div className="page-header__bg"></div>
        <div className="container">
          <div className="page-header__inner">
            <h2>Contact Us</h2>
            <div className="thm-breadcrumb__box">
              <ul className="thm-breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <span>::</span>
                </li>
                <li>Contact Us</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* <!--Page Header End--> */}

      {/* <!--Get In Touch Two Start--> */}
      <section className="get-in-touch-two">
        <div className="container">
          <div className="section-title text-left">
            <div className="section-title__tagline-box">
              <span className="section-title__tagline">. : Have Questions?</span>
            </div>
            <h2 className="section-title__title">Get in Touch!</h2>
          </div>
          <div className="get-in-touch-two__inner">
            <ul className="get-in-touch-two__list">
              <li>
                <div className="icon">
                  <span className="icon-location"></span>
                </div>
                <div className="content">
                  <h3>Office Address</h3>
                  <p>
                    Eacham Pottal Pudur, <br /> Tenkasi, Tamilnadu - 627755
                  </p>
                </div>
              </li>
              <li>
                <div className="icon">
                  <span className="icon-phone-1"></span>
                </div>
                <div className="content">
                  <h3>Phone No.</h3>
                  <p>
                    <a>+91 956 693 2342</a>
                  </p>
                  <p>
                    <a>+91 807 273 0120</a>
                  </p>
                </div>
              </li>
              <li>
                <div className="icon">
                  <span className="icon-mail-1"></span>
                </div>
                <div className="content">
                  <h3>Mail Address</h3>
                  <p>
                    <a>info@tachtag.in </a>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/* <!--Get In Touch Two End--> */}
      

      {/* <!--Google Map Start--> */}
      <section className="google-map">
        <div className="container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d205.46845497842392!2d77.4725695306707!3d9.168911299188643!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0697909f9bcea5%3A0x33ddd047ba269687!2sEcham%20Pottal%20Pudur!5e1!3m2!1sen!2sin!4v1720709714982!5m2!1sen!2sin"
            className="google-map__one"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
      {/* <!--Google Map End--> */}


      {/* <section className="get-in-touch-two">
      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label htmlFor="name">Name</label>
            <input {...register("name", { required: true })} id="name" className="form-control" />
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              {...register("email", { required: true })}
              id="email"
              className="form-control"
            />
          </div>
          <div>
            <label htmlFor="message">Message</label>
            <textarea
              {...register("message", { required: true })}
              id="message"
              className="form-control"
            />
          </div>
          <button type="submit" className="btn btn-success">Send</button>
        </form>
        </div>
      </section> */}

      {/* <!--Contact Page Start--> */}
      <section className="contact-page">
                <div className="container">
                    <div className="contact-page__inner">
                        <h3 className="contact-page__title">What are you looking for?</h3>
                        <div className="contact-page__form">
                            <form onSubmit={handleSubmit(onSubmit)} className="contact-page__form-box"
                                novalidate="novalidate">
                                <div className="row">
                                    <div className="col-xl-6">
                                        <div className="contact-page__input-box">
                                            <input type="text" name="name" placeholder="Name" {...register("name", { required: true })} />
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="contact-page__input-box">
                                            <input type="text" name="email" placeholder="Email" {...register("email", { required: true })} />
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="contact-page__input-box">
                                            <input type="text" name="subject" placeholder="subject" {...register("subject", { required: true })} />
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="contact-page__input-box">
                                            <input type="text" name="phone" placeholder="Phone" {...register("phone", { required: true })}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="contact-page__input-box text-message-box">
                                            <textarea name="message" placeholder="Massage" {...register("message", { required: true })}></textarea>
                                        </div>
                                        <div className="contact-page__btn-box">
                                            <button type="submit" className="contact-page__btn">Send Message</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
      {/* <!--Contact Page End--> */}
    </main>
  );
};
export default Contact;
