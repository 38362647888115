import React from 'react';
import { Link } from 'react-router-dom';
const UiuxDesign = () => {

    return (
        <main>
        	{/* <!--Page Header Start--> */}
		<section class="page-header">
			<div class="page-header__bg"></div>
			<div class="container">
				<div class="page-header__inner">
					<h2>UI / UX Designing</h2>
					<div class="thm-breadcrumb__box">
						<ul class="thm-breadcrumb">
                        <li><Link to="/">Home</Link></li>
							<li><span>::</span></li>
							<li><Link to="/services">services</Link></li>
                            <li><span>::</span></li>
							<li>UI/UX</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
		{/* <!--Page Header End--> */}

            {/* <!--Services Details Start--> */}
		<section className="services-details">
			<div className="container">
				<h3 className="services-details__title-1">Innovative  UI / UX Designing services</h3>
				<p className="services-details__text-1">At TECHTAG, we believe that great design is at the heart of every successful digital product. Our UI/UX design services focus on creating intuitive and engaging user experiences that drive user satisfaction and business growth.

</p>
				{/* <div className="services-details__img-1">
					<img src="images/services/services-details-img-1.jpg" alt=""/>
				</div> */}
				<div className="services-details__middle">
					<div className="row">
						<div className="col-xl-4 col-lg-4">
							<div className="services-details__middle-img">
								<img src="images/services/services-details-middle-img-1.jpg" alt=""/>
							</div>
						</div>
						<div className="col-xl-8 col-lg-8">
							<div className="services-details__middle-content">
								<h3 className="services-details__middle-title">User Research</h3>
								<p className="services-details__middle-text">Understanding our users is the first step to creating a product they love. We conduct thorough user research to gain insights into user behaviors, needs, and pain points, ensuring that our designs are user-centric and effective. In today's multi-device world, responsiveness is key. We ensure that your product looks and functions perfectly across all devices, providing a consistent user experience on desktops, tablets, and mobile phones. We validate our designs through rigorous usability testing. By observing real users interact with the product, we identify areas for improvement and make data-driven adjustments to enhance usability and satisfaction.</p>
                                
                            </div>
						</div>
						
					</div>
				</div>
				<div className="services-details__what-we-do">
					<div className="row">
						<div className="col-xl-8 col-lg-7">
							<div className="services-details__what-we-do-left">
								<h3 className="services-details__what-we-do-title">Wireframing & Prototyping</h3>
								<p className="services-details__what-we-do-text-1">We transform ideas into tangible concepts with detailed wireframes and interactive prototypes. This stage allows us to visualize the user journey, test functionalities, and refine the design before development begins. </p>
								<h3 className="services-details__what-we-do-title">User Interface (UI) Design</h3>
                                <p className="services-details__what-we-do-text-1">Our UI designers craft visually appealing and cohesive interfaces that reflect your brand identity. We focus on creating designs that are not only beautiful but also functional, ensuring a seamless user experience.</p>
                                <h3 className="services-details__what-we-do-title">User Experience (UX) Design</h3>
                                <p className="services-details__what-we-do-text-1">We design with the user in mind, ensuring that every interaction is intuitive and enjoyable. Our UX design process involves creating user flows, information architecture, and interaction design to optimize the overall user journey. Our team of skilled UI/UX designers has extensive experience across various industries. We bring creativity, technical expertise, and a deep understanding of design principles to every project.</p>
                                <h3 className="services-details__what-we-do-title">Innovative Solutions & Proven Success</h3>
                                <p className="services-details__what-we-do-text-1">We stay ahead of design trends and leverage the latest tools and technologies to create innovative solutions that give you a competitive edge. Our portfolio speaks for itself. We have successfully designed digital products that enhance user satisfaction, drive engagement, and achieve business goals.</p>
								<div class="about-one__btn-box">
                                            <Link to="/services" className="about-one__btn thm-btn">View All Our Services</Link>
                                        </div>
							</div>
						</div>
						<div className="col-xl-4 col-lg-5">
							<div className="services-details__what-we-do-img mb-3">
								<img src="images/services/services-details-what-we-do-img.jpg" alt=""/>
							</div>
                            <div className="services-details__included-services">
								<h4 className="services-details__included-services-title">Included Services</h4>
								<ul className="services-details__included-services-list">
									<li>
										<div className="services-details__included-services-shape"></div>
										<p className="services-details__included-services-text">Monthly SEO Task</p>
									</li>
									<li>
										<div className="services-details__included-services-shape"></div>
										<p className="services-details__included-services-text">24/7 Alltime Supporting</p>
									</li>
									<li>
										<div className="services-details__included-services-shape"></div>
										<p className="services-details__included-services-text">Turbo Boosting</p>
									</li>
									<li>
										<div className="services-details__included-services-shape"></div>
										<p className="services-details__included-services-text">Social Media Marketing</p>
									</li>
                                    <li>
										<div className="services-details__included-services-shape"></div>
										<p className="services-details__included-services-text">Domain & Hosting</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				
				
			</div>
		</section>
		{/* <!--Services Details End--> */}
        </main>

    );
};
export default UiuxDesign;