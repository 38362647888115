import React from 'react';
import { Link } from 'react-router-dom';
const WebDevelopement = () => {

    return (
        <main>
             	{/* <!--Page Header Start--> */}
		<section class="page-header">
			<div class="page-header__bg"></div>
			<div class="container">
				<div class="page-header__inner">
					<h2>Web Development</h2>
					<div class="thm-breadcrumb__box">
						<ul class="thm-breadcrumb">
                        <li><Link to="/">Home</Link></li>
							<li><span>::</span></li>
							<li><Link to="/services">services</Link></li>
                            <li><span>::</span></li>
							<li>Web Development</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
		{/* <!--Page Header End--> */}

            {/* <!--Services Details Start--> */}
		<section className="services-details">
			<div className="container">
				<h3 className="services-details__title-1">Our Creative Web Development Services</h3>
				<p className="services-details__text-1">We bring your creative vision to life. As a leading creative development agency, we specialize in delivering innovative solutions that transform ideas into captivating digital experiences. Our expert developers create dynamic, responsive websites that are both visually stunning and highly functional. We ensure your online presence is engaging and user-friendly, tailored to meet your business goals.
</p>
				{/* <div className="services-details__img-1">
					<img src="images/services/services-details-img-1.jpg" alt=""/>
				</div> */}
				<div className="services-details__middle">
					<div className="row">
						<div className="col-xl-4 col-lg-4">
							<div className="services-details__middle-img">
								<img src="images/services/services-details-middle-img-1.jpg" alt=""/>
							</div>
						</div>
						<div className="col-xl-8 col-lg-8">
							<div className="services-details__middle-content">
								<h3 className="services-details__middle-title">Custom Web Development</h3>
								<p className="services-details__middle-text">We offer tailor-made web development solutions that cater to your specific business needs. Our custom websites are designed to reflect your brand identity and provide a seamless user experience.</p>
                                <ul  className="project-details__content-points mt-3">
									<li><div class="project-details__content-shape"></div>Bespoke website design and development</li>
									<li><div class="project-details__content-shape"></div>User-friendly interfaces and intuitive navigation</li>
									<li><div class="project-details__content-shape"></div>Scalable and flexible solutions to grow with your business</li>
									
								</ul>
                            </div>
						</div>
						
					</div>
				</div>
				
				
				
			</div>
		</section>
		{/* <!--Services Details End--> */}

		{/* <!--Services Two Start--> */}
		<section class="services-two p-3">
			<div class="services-two__shape-1" style={{ backgroundImage: 'url(images/shapes/services-two-shape-1.png)' }}>
			</div>
			<div class="container">
				<div class="services-two__inner-box">
					<div class="row">

						<div class="col-xl-5 wow fadeInLeft" data-wow-delay="100ms">
							<div class="services-two__left">
								<div class="section-title text-left">
									<div class="section-title__tagline-box">
										<span class="section-title__tagline">. : Trusted web design service : .</span>
									</div>
									<h2 class="section-title__title">Intuitive For
										Web Solution</h2>
								</div>
							</div>
						</div>

						<div class="col-xl-7">
							<div class="services-two__right">
								<ul class="services-two__list clearfix">
									<li class="cs-hover_tab-2 clearfix">
										<div class="services-two__icon">
											<a href="/"><span class="icon-down-left-arrow"></span></a>
										</div>
										<div class="services-two__content">
											<div class="services-two__title-box">
												<div class="services-two__count"></div>
												<h3 class="services-two__title"><a href="/">B2B Portal Development</a></h3>
											</div>
											<p class="services-two__text">A B2B portal is a platform, where businesses advertise their listing and seeing people relying on internet to buy anything, B2B portals are really successful if these are made SEO friendly, high functionality and under unique strategy.</p>
										</div>
										<div class="services-two__img-box" data-src="images/services/services-two-img-1.jpg"></div>
									</li>
									<li class="cs-hover_tab-2 clearfix">
										<div class="services-two__icon">
											<a href="/"><span
													class="icon-down-left-arrow"></span></a>
										</div>
										<div class="services-two__content">
											<div class="services-two__title-box">
												<div class="services-two__count"></div>
												<h3 class="services-two__title"><a href="/">B2C Portal Development</a></h3>
											</div>
											<p class="services-two__text">Looking for a B2C marketplace, where you can make vendors sell their products through your portal and you can make profit by managing them? If yes, Techtag can surely fulfill your requirement. No matter, your requirement is whatsoever complicated or big like Flipkart or Amazon, we have experienced programmers and developers who have already worked on such B2C Portal development projects.
											</p>
										</div>
										<div class="services-two__img-box"
											data-src="images/services/services-two-img-2.jpg"></div>
									</li>
									<li class="cs-hover_tab-2 clearfix active">
										<div class="services-two__icon">
											<a href="/"><span class="icon-down-left-arrow"></span></a>
										</div>
										<div class="services-two__content">
											<div class="services-two__title-box">
												<div class="services-two__count"></div>
												<h3 class="services-two__title"><a href="/">Software Development</a></h3>
											</div>
											<p class="services-two__text">Online Exam System / Portal / Websites with lots of features.
											We develop an Online Exams System for your institution to be able to create paperless, timer-based online examinations for your students. This software is highly demanded by institutes, recruiters, schools, colleges and universities. 
											</p>
										</div>
										<div class="services-two__img-box"
											data-src="images/services/services-two-img-3.jpg"></div>
									</li>
									<li class="cs-hover_tab-2 clearfix">
										<div class="services-two__icon">
											<a href="/"><span class="icon-down-left-arrow"></span></a>
										</div>
										<div class="services-two__content">
											<div class="services-two__title-box">
												<div class="services-two__count"></div>
												<h3 class="services-two__title"><a href="/">Web Hosting</a></h3>
											</div>
											<p class="services-two__text">Website maintenance & Hosting is crucial for ensuring the smooth operation and longevity of a website. Our dedicated POC’s help you maintain and update the website as your business requires.
											</p>
											<br/>
											<div class="d-flex justify-content-end about-one__btn-box">
                                            <Link to="/services" className="about-one__btn thm-btn">View All Our Services</Link>
                                        </div>
										</div>
										<div class="services-two__img-box"
											data-src="images/services/services-two-img-4.jpg"></div>
											
									</li>
									
								</ul>
								
							</div>
						</div>


					</div>

				</div>
			</div>
		</section>
		{/* <!--Services Two End--> */}
        </main>

    );
};
export default WebDevelopement;