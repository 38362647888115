import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useBodyClass } from "./BodyClassContext";

// import './Header.css';

const Header = () => {
  const { setBodyClass } = useBodyClass();
  const [clickbar, setClickbar] = useState(false);
  useEffect(() => {
    return () => {
      setBodyClass(""); // Optionally remove the class when this component unmounts
    };
  }, [setBodyClass]);

  function submitbar(chk) {
    if (chk) {
      if (window.innerWidth < 767) {
        setClickbar(chk);
        setBodyClass("mobile-menu-visible");
      }
    } else {
      setClickbar(chk);
      setBodyClass("");
    }
  }
  const navigate = useNavigate();
  function submobile(smenu) {
    navigate(smenu);
    window.location.reload();
  }
  return (
    <header className="main-header main-header-three">
      <div className="main-header-three__wrap">
        <div className="main-header-three__lower">
          <div className="container">
            <div className="main-header-three__lower-inner">
              {/* <!-- Logo Box --> */}
              <div className="main-header-three__logo-box">
                <Link to="/">
				
                  <img src="images/resource/logo-1.png" alt="" />
                </Link>
              </div>
              <div className="main-menu-two__box">
                <div className="main-menu-two__nav-outer-box">
                  <div className="nav-outer">
                    {/* <!-- Main Menu --> */}
                    <nav className="main-menu show navbar-expand-md">
                      <div className="navbar-header">
                        <button
                          className="navbar-toggler"
                          type="button"
                          data-toggle="collapse"
                          data-target="#navbarSupportedContent"
                          aria-controls="navbarSupportedContent"
                          aria-expanded="false"
                          aria-label="Toggle navigation"
                        >
                          <span className="icon-bar"></span>
                          <span className="icon-bar"></span>
                          <span className="icon-bar"></span>
                        </button>
                      </div>

                      <div
                        className="navbar-collapse collapse"
                        id="navbarSupportedContent"
                      >
                        <ul className="navigation">
                          <li>
                            <Link to="/">Home</Link>
                          </li>
                          <li>
                            <Link to="about">About</Link>
                          </li>
                          <li>
                            <Link to="services">Services</Link>
                          </li>
                          {/* <li><Link to="works">Our Works</Link></li> */}
                          <li>
                            <Link to="clients">Clients</Link>
                          </li>
                          <li>
                            <Link to="contact">Contact</Link>
                          </li>
                        </ul>
                      </div>
                    </nav>
                    {/* <!-- Main Menu End--> */}
                  </div>
                  {/* <!-- Mobile Navigation Toggler --> */}
                  <div
                    className="mobile-nav-toggler"
                    onClick={() => submitbar(true)}
                  >
                    <span className="fas fa-bars" style={{ color: "#ff5c00" }}></span>
                  </div>
                </div>
              </div>
              <div className="main-menu__call">
                <div className="main-menu__call-icon">
                  <i className="icon-clock"></i>
                </div>
                <div className="main-menu__call-content">
                  <p className="main-menu__call-sub-title">Call Any Time</p>
                  <h5 className="main-menu__call-number">
                    <a href="tel:+919566932342">+91 9566-932-342</a>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-menu">
        <div className="menu-backdrop"></div>
        <div className="close-btn" onClick={() => submitbar(false)}>
          <span className="icon fa fa-times fa-fw"></span>
        </div>
        <nav className="menu-box">
          <div className="nav-logo">
            <a >
			<img src="images/logo.png" alt="" />
              {/* <img src="assets/images/logo-2.png" alt="" title="" /> */}
            </a>
          </div>

          <div className="menu-outer">
            <div className="navbar-header">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </div>

            <div className="navbar-collapse collapse" id="navbarSupportedContent">
              <ul className="navigation">
                <li onClick={() => submobile("/")}>
                  <a>Home</a>
                </li>
                <li onClick={() => submobile("about")}>
                  <a>About</a>
                </li>
                <li onClick={() => submobile("services")}>
                  <a>Services</a>
                </li>
                {/* <li><Link to="works">Our Works</Link></li> */}
                <li onClick={() => submobile("clients")}>
                  <a>Clients</a>
                </li>
                <li onClick={() => submobile("contact")}>
                  <a>Contact</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
