import React from "react";
import { Link } from "react-router-dom";
const Terms = () => {
  return (
    <main>
      <section className="page-header">
        <div className="page-header__bg"></div>
        <div className="container">
          <div className="page-header__inner">
            <h2>Terms of Use</h2>
            <div className="thm-breadcrumb__box">
              <ul className="thm-breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <span>::</span>
                </li>
                <li>Terms of Use</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="services-details">
        <div className="container">
          <p className="about-three__text-2">Acceptance of Terms</p>
          <p className="about-three__text-3">
            Outlines that by using the site, you agree to abide by their rules
            and policies.
          </p>

          <p className="about-three__text-2">User Responsibilities</p>
          <p className="about-three__text-3">
            Specifies what is expected from users, such as not engaging in
            illegal activities or infringing on others' rights.
          </p>

          <p className="about-three__text-2">Intellectual Property</p>
          <p className="about-three__text-3">
            Details who owns the content on the site and how it can be used or
            shared.
          </p>

          <p className="about-three__text-2">Account Management</p>
          <p className="about-three__text-3">
            Describes rules for account creation, management, and termination.
          </p>

          <p className="about-three__text-2">Privacy Policy</p>
          <p className="about-three__text-3">
            Refers to how the site handles user data and privacy.
          </p>

          <p className="about-three__text-2">Limitation of Liability</p>
          <p className="about-three__text-3">
            Limits the site's responsibility for any damages that may arise from
            using the site.
          </p>
        </div>
      </section>
    </main>
  );
};
export default Terms;
