import React from 'react';
import { Link } from 'react-router-dom';
const About = () => {
    return (
        <main>
            <section className="page-header">
                <div className="page-header__bg"></div>
                <div className="container">
                    <div className="page-header__inner">
                        <h2>About us</h2>
                        <div className="thm-breadcrumb__box">
                            <ul className="thm-breadcrumb">
                                <li><Link to="/">Home</Link></li>
                                <li><span>::</span></li>
                                <li>About us</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>


            <section class="about-one">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-6">
                            <div class="about-one__left">
                                <div class="about-one__img-box wow slideInLeft" data-wow-delay="100ms"
                                    data-wow-duration="2500ms">
                                    <div class="about-one__img">
                                        <img src="images/resource/about-one-img-11.jpg" alt="" />
                                    </div>
                                    <div class="about-one__img-2">
                                        <img src="images/resource/about-one-img-2.jpg" alt="" />
                                    </div>
                                    <div class="about-one__shape-1 zoominout">
                                        <img src="images/shapes/about-one-shape-1.png" alt="" />
                                    </div>
                                    <div class="about-one__shape-2 rotateme">
                                        <img src="images/shapes/about-one-shape-2.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6">
                            <div class="about-one__right wow fadeInRight" data-wow-delay="300ms">
                                <div class="section-title text-left">
                                    <div class="section-title__tagline-box">
                                        <span class="section-title__tagline">. : about TechTag : .</span>
                                    </div>
                                    <h2 class="section-title__title">Smart Development for a Digital Future
                                    </h2>
                                </div>
                                <p class="about-one__text">We start by understanding your goals, target audience, and competition. This helps us create a solid foundation for your project.
                                </p>
                                <div class="about-one__standard-and-img-box">
                                    <div class="about-one__standard-box">
                                        <h3>High Standard</h3>
                                        <p className='text-justify'>Our relationship doesn't end at launch. We provide ongoing support and maintenance to keep your project running smoothly and efficiently.</p>
                                        <div class="about-one__btn-box">
                                            <Link to="/services" className="about-one__btn thm-btn">View All Our Services</Link>
                                        </div>
                                    </div>
                                    <div className="about-one__standard-img">
                                        <img src="images/resource/about-one-standard-img1.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

		{/* <!-- Process One Start --> */}
		<section className="process-one">
			<div className="container">
				<div className="section-title text-center">
					<div className="section-title__tagline-box">
						<span className="section-title__tagline">. : SIMPLE WORK PROCESS : .</span>
					</div>
					<h2 className="section-title__title">see our working process
						<br /> how we manage</h2>
				</div>
				<div className="row">
					{/* <!--Process One Single Start--> */}
					<div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms">
						<div className="process-one__single">
							<h3 className="process-one__title">Expert Team</h3>
							<p className="process-one__text">Our team consists of skilled professionals with years of experience in their respective fields.
							</p>
							<div className="process-one__count"></div>
						</div>
					</div>
					{/* <!--Process One Single End--> */}
					{/* <!--Process One Single Start--> */}
					<div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="200ms">
						<div className="process-one__single margin-top">
							<h3 className="process-one__title">Innovative Solutions</h3>
							<p className="process-one__text">Lorem ipsum dolor sit amet, consectetur elit sed do eiusmod.
							</p>
							<div className="process-one__count"></div>
						</div>
					</div>
					{/* <!--Process One Single End--> */}
					{/* <!--Process One Single Start--> */}
					<div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="300ms">
						<div className="process-one__single">
							<h3 className="process-one__title">Client-Centric Approach</h3>
							<p className="process-one__text">Lorem ipsum dolor sit amet, consectetur elit sed do eiusmod.
							</p>
							<div className="process-one__count"></div>
						</div>
					</div>
					{/* <!--Process One Single End--> */}
					{/* <!--Process One Single Start--> */}
					<div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="400ms">
						<div className="process-one__single margin-top">
							<h3 className="process-one__title">Proven Track Record</h3>
							<p className="process-one__text">Lorem ipsum dolor sit amet, consectetur elit sed do eiusmod.
							</p>
							<div className="process-one__count"></div>
						</div>
					</div>
					{/* <!--Process One Single End--> */}
				</div>
                <div className="about-one__bottom">
                    <p>Contact Us or Send An <span>Email</span> to info<a href="/">@techtag.com</a></p>
                </div>
			</div>

		</section>
		{/* <!-- Process One End --> */}

            {/* <!-- Get In Touch Start --> */}
            <section className="get-in-touch">
                <div className="container">
                    <div className="get-in-touch__inner">
                        <div className="get-in-touch__top">
                            <div className="get-in-touch__top-img">
                                <img src="images/resource/get-in-touch-img-1.jpg" alt="" />
                            </div>
                            <div className="get-in-touch__top-btn">
                                <a href="contact.html"><span className="icon-bottom-right"></span></a>
                            </div>
                        </div>
                        <div className="get-in-touch__bottom">
                            <h2 className="get-in-touch__title">Have any projects in mind? <br />
                                Get in touch with us!</h2>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Get In Touch End --> */}


            {/* <!--Brand One Start--> */}
            <section className="brand-one brand-two d-none">
                <div className="container">
                    <div className="brand-one__inner">
                        <div className="thm-swiper__slider swiper-container" data-swiper-options='{"spaceBetween": 100,
                        "slidesPerView": 5,
                        "loop": true,
                        "navigation": {
                            "nextEl": "#brand-one__swiper-button-next",
                            "prevEl": "#brand-one__swiper-button-prev"
                        },
                        "autoplay": { "delay": 5000 },
                        "breakpoints": {
                            "0": {
                                "spaceBetween": 30,
                                "slidesPerView": 2
                            },
                            "375": {
                                "spaceBetween": 30,
                                "slidesPerView": 2
                            },
                            "575": {
                                "spaceBetween": 30,
                                "slidesPerView": 3
                            },
                            "767": {
                                "spaceBetween": 50,
                                "slidesPerView": 4
                            },
                            "991": {
                                "spaceBetween": 50,
                                "slidesPerView": 5
                            },
                            "1199": {
                                "spaceBetween": 100,
                                "slidesPerView": 5
                            }
                        }}'>
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="brand-one__single">
                                        <div className="brand-one__img">
                                            <img src="images/brand/brand-1-1.png" alt="" />
                                        </div>
                                        <div className="brand-one__hover-img">
                                            <img src="images/brand/brand-hover-1-1.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-one__single">
                                        <div className="brand-one__img">
                                            <img src="images/brand/brand-1-2.png" alt="" />
                                        </div>
                                        <div className="brand-one__hover-img">
                                            <img src="images/brand/brand-hover-1-2.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-one__single">
                                        <div className="brand-one__img">
                                            <img src="images/brand/brand-1-3.png" alt="" />
                                        </div>
                                        <div className="brand-one__hover-img">
                                            <img src="images/brand/brand-hover-1-3.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-one__single">
                                        <div className="brand-one__img">
                                            <img src="images/brand/brand-1-4.png" alt="" />
                                        </div>
                                        <div className="brand-one__hover-img">
                                            <img src="images/brand/brand-hover-1-4.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-one__single">
                                        <div className="brand-one__img">
                                            <img src="images/brand/brand-1-5.png" alt="" />
                                        </div>
                                        <div className="brand-one__hover-img">
                                            <img src="images/brand/brand-hover-1-5.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-one__single">
                                        <div className="brand-one__img">
                                            <img src="images/brand/brand-1-1.png" alt="" />
                                        </div>
                                        <div className="brand-one__hover-img">
                                            <img src="images/brand/brand-hover-1-1.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-one__single">
                                        <div className="brand-one__img">
                                            <img src="images/brand/brand-1-2.png" alt="" />
                                        </div>
                                        <div className="brand-one__hover-img">
                                            <img src="images/brand/brand-hover-1-2.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-one__single">
                                        <div className="brand-one__img">
                                            <img src="images/brand/brand-1-3.png" alt="" />
                                        </div>
                                        <div className="brand-one__hover-img">
                                            <img src="images/brand/brand-hover-1-3.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-one__single">
                                        <div className="brand-one__img">
                                            <img src="images/brand/brand-1-4.png" alt="" />
                                        </div>
                                        <div className="brand-one__hover-img">
                                            <img src="images/brand/brand-hover-1-4.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="brand-one__single">
                                        <div className="brand-one__img">
                                            <img src="images/brand/brand-1-5.png" alt="" />
                                        </div>
                                        <div className="brand-one__hover-img">
                                            <img src="images/brand/brand-hover-1-5.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--Brand One End--> */}





        </main>





    );
};
export default About;