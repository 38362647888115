import React, { createContext, useContext, useEffect, useState } from 'react';

const BodyClassContext = createContext();

export const BodyClassProvider = ({ children }) => {
  const [bodyClass, setBodyClass] = useState('');

  useEffect(() => {
    if (bodyClass) {
      document.body.classList.add(bodyClass);
    }

    return () => {
      if (bodyClass) {
        document.body.classList.remove(bodyClass);
      }
    };
  }, [bodyClass]);

  return (
    <BodyClassContext.Provider value={{ setBodyClass }}>
      {children}
    </BodyClassContext.Provider>
  );
};

export const useBodyClass = () => useContext(BodyClassContext);
