import React from 'react';
import { Link } from 'react-router-dom';
const WordpressDev = () => {

    return (
        <main>
        	{/* <!--Page Header Start--> */}
		<section class="page-header">
			<div class="page-header__bg"></div>
			<div class="container">
				<div class="page-header__inner">
					<h2>Wordpress Development</h2>
					<div class="thm-breadcrumb__box">
						<ul class="thm-breadcrumb">
                        <li><Link to="/">Home</Link></li>
							<li><span>::</span></li>
							<li><Link to="/services">services</Link></li>
                            <li><span>::</span></li>
							<li>Wordpress</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
		{/* <!--Page Header End--> */}

            {/* <!--Services Details Start--> */}
		<section className="services-details">
			<div className="container">
				<h3 className="services-details__title-1">Our WordPress Development Services</h3>
				<p className="services-details__text-1">We specialize in creating powerful, customizable, and scalable WordPress websites that help businesses thrive online. Our team of experienced developers is dedicated to delivering top-notch solutions tailored to your unique needs.</p>
				{/* <div className="services-details__img-1">
					<img src="images/services/services-details-img-1.jpg" alt=""/>
				</div> */}
				<div className="services-details__middle">
					<div className="row">
						
						<div className="col-xl-8 col-lg-8">
							<div className="services-details__middle-content">
								<h3 className="services-details__middle-title">WordPress Plugin Development</h3>
								<p className="services-details__middle-text">Our developers build custom plugins to extend the functionality of your WordPress site. Whether you need a simple widget or a complex plugin, we ensure it integrates seamlessly with your website.</p>
                                <p className="services-details__middle-text">We provide comprehensive e-commerce solutions using WooCommerce, the leading WordPress e-commerce platform. From setting up your store to customizing its features, we help you create a seamless online shopping experience.</p>
                            </div>
						</div>
                        <div className="col-xl-4 col-lg-4">
							<div className="services-details__middle-img">
								<img src="images/services/services-details-middle-img-1.jpg" alt=""/>
							</div>
						</div>
						
					</div>
				</div>
				<div className="services-details__what-we-do">
					<div className="row">
                    <div className="col-xl-4 col-lg-5">
							<div className="services-details__what-we-do-img mb-3">
								<img src="images/services/services-details-what-we-do-img.jpg" alt=""/>
							</div>
                            <div className="services-details__included-services">
								<h4 className="services-details__included-services-title">Included Services</h4>
								<ul className="services-details__included-services-list">
									<li>
										<div className="services-details__included-services-shape"></div>
										<p className="services-details__included-services-text">Monthly SEO Task</p>
									</li>
									<li>
										<div className="services-details__included-services-shape"></div>
										<p className="services-details__included-services-text">24/7 Alltime Supporting</p>
									</li>
									<li>
										<div className="services-details__included-services-shape"></div>
										<p className="services-details__included-services-text">Turbo Boosting</p>
									</li>
									<li>
										<div className="services-details__included-services-shape"></div>
										<p className="services-details__included-services-text">Social Media Marketing</p>
									</li>
                                    <li>
										<div className="services-details__included-services-shape"></div>
										<p className="services-details__included-services-text">Domain & Hosting</p>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-xl-8 col-lg-7">
							<div className="services-details__what-we-do-left">
								<h3 className="services-details__what-we-do-title">WordPress Migration</h3>
								<p className="services-details__what-we-do-text-1">We handle the entire migration process from your current platform to WordPress, ensuring a smooth transition with minimal downtime. Our team takes care of data migration, theme customization, and plugin integration.</p>
								<h3 className="services-details__what-we-do-title">WordPress Maintenance & Support</h3>
                                <p className="services-details__what-we-do-text-1">Our maintenance and support services ensure your WordPress site is always up-to-date, secure, and running smoothly. We offer regular updates, backups, security monitoring, and performance optimization.</p>
                                <h3 className="services-details__what-we-do-title">SEO & Performance Optimization</h3>
                                <p className="services-details__what-we-do-text-1">We optimize your WordPress site for search engines and performance, helping you achieve higher rankings and faster load times. Our SEO services include keyword research, on-page optimization, and content strategy.</p>
                                <h3 className="services-details__what-we-do-title">Innovative Solutions & Proven Success</h3>
                                <p className="services-details__what-we-do-text-1">We stay ahead of design trends and leverage the latest tools and technologies to create innovative solutions that give you a competitive edge. Our portfolio speaks for itself. We have successfully designed digital products that enhance user satisfaction, drive engagement, and achieve business goals.</p>
								<div class="d-flex justify-content-end about-one__btn-box">
                                            <Link to="/services" className="about-one__btn thm-btn">View All Our Services</Link>
                                        </div>
							</div>
						</div>
						
					</div>
				</div>
				
				
			</div>
		</section>
		{/* <!--Services Details End--> */}
        </main>

    );
};
export default WordpressDev;