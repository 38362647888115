import React from "react";
import { Link } from "react-router-dom";
const PrivacyPoilcy = () => {
  return (
    <main>
      <section className="page-header">
        <div className="page-header__bg"></div>
        <div className="container">
          <div className="page-header__inner">
            <h2>Privacy Policy</h2>
            <div className="thm-breadcrumb__box">
              <ul className="thm-breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <span>::</span>
                </li>
                <li>Privacy Policy</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="services-details">
        <div className="container">
          <p className="about-three__text">
            General Outline of a Privacy Policy for a Development Company
            Introduction Overview of the company and its commitment to
            protecting user privacy.
          </p>
          <p className="about-three__text-2">
            Information Collection Personal Information
          </p>
          <p className="about-three__text-3">
            Types of personal data collected (e.g., contact details, project
            information). Usage Data: Information collected automatically (e.g.,
            IP addresses, browser types).
          </p>

          <p className="about-three__text-2">
            How Information is Used Service Delivery
          </p>
          <p className="about-three__text-3">
            How collected information is used to provide and manage services.
          </p>

          <p className="about-three__text-2">Improvement and Development</p>
          <p className="about-three__text-3">
            How data is used to enhance website functionality and user
            experience.
          </p>

          <p className="about-three__text-2">Communication</p>
          <p className="about-three__text-3">
            How information is used to contact users about services, updates, or
            marketing. Data Sharing and Disclosure Third-Party Service
          </p>

          <p className="about-three__text-2">Providers</p>
          <p className="about-three__text-3">
            Sharing information with third parties who provide services on
            behalf of the company (e.g., payment processors, analytics
            providers).
          </p>

          <p className="about-three__text-2">Legal Requirements</p>
          <p className="about-three__text-3">
            Circumstances under which data may be disclosed for legal reasons.
            Data Security Measures taken to protect user data from unauthorized
            access and breaches.
          </p>

          <p className="about-three__text-2">
            User Rights Access and Correction
          </p>
          <p className="about-three__text-3">
            How data is used to enhance website functionality and user
            experience.How users can access, update, or correct their personal
            information.
          </p>

          <p className="about-three__text-2">Data Deletion</p>
          <p className="about-three__text-3">
            How users can request the deletion of their data.
          </p>
<br/>
          <p className="about-three__text-3">
            Cookies and Tracking Technologies Information about the use of
            cookies and other tracking technologies to collect user behavior
            data. Policy Changes How users will be notified of changes to the
            privacy policy. Contact Information Contact details for users to ask
            questions or express concerns about the privacy policy. <br/><br/>For the
            exact details, visit TechTag’s website and locate their privacy
            policy. <br/><br/>If you have specific concerns or need clarification on
            particular aspects of their privacy practices, reviewing the actual
            document on their site will provide the most accurate and relevant
            information.
          </p>
        </div>
      </section>
    </main>
  );
};
export default PrivacyPoilcy;
